<template>
  <div eagle-form-item="banner">
    <bannerEditor
      :disabled="disabled || readonly"
      v-model="data"
    ></bannerEditor>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data: () => ({
    data: null,
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
  components: {
    bannerEditor: () => import('components/bannerEditor/bannerEditor.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
